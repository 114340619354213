import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Header from "src/components/common/header";
import styled from "styled-components";
import EditGuardiansView from "./EditGuardiansView";
import EditGuardiansSearch from "./EditGuardiansSearch";
import {
  isSameName,
  signForBe,
  stringArrayEqual,
} from "src/utils/zkkontosHelper";
import toast from "src/components/toast/Toast";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import { Sheet } from "react-modal-sheet";
import SetUpThreshold from "src/components/start/SetUpThreshold";
import { Unlock } from "src/pages/sign-up/unlock/Unlock";
import { KontosKey, KontosQueryCli } from "@zkkontos/kontos-sdk";
import { loadingStore } from "src/store/loadingStore";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { fontBold } from "@/style/style.global";
import { COMMON_SIGN_EXPIREDAT } from "@/config";
import { callUpdateAccountGuardian } from "@/apis/aa-apis";
import { uploadErrorOnce } from "@/service/wallet-service";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
  }
`;

const RedText = styled.span`
  color: ${(props) => props.theme.colors.__error};
  ${fontBold};
  font-size: 14px;
`;

enum EditGuardiansStep {
  View,
  Search,
}

interface Props {
  guardianData: string[];
  onClose: () => void;
  onSuccess: () => void;
  onInnerModalChange?: (isOpen: boolean) => void;
}

export type GuardianEditType = {
  rawName: string | undefined;
  name: string;
};

const EditGuardians: React.FC<Props> = ({
  guardianData,
  onClose,
  onSuccess,
  onInnerModalChange,
}) => {
  const { userStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const rawGuardianList: string[] = guardianData.filter(
    (name) => !isSameName(name, userStore.accountInfo?.name || "")
  );
  const [guardians, setGuardians] = useState<GuardianEditType[]>(
    guardianData
      .map((name) => {
        return { rawName: name, name: name };
      })
      .filter(
        (guardian) =>
          !isSameName(guardian.name, userStore.accountInfo?.name || "")
      )
  );
  const [step, setStep] = useState<EditGuardiansStep>(EditGuardiansStep.View);
  const [editingGuardian, setEditingGuardian] = useState<
    GuardianEditType | undefined
  >(undefined);
  const [changedGuardianName, setChangedGuardianName] = useState<
    string | undefined
  >(undefined);
  const [adding, setAdding] = useState<boolean>(false);
  const [showThresHoldModal, setShowThresHoldModal] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const rawThreshold = userStore.accountInfo?.actionThreshold || 1;
  const [currentThreshold, setCurrentThreshold] =
    useState<number>(rawThreshold);

  const headerText: string = useMemo(() => {
    switch (step) {
      case EditGuardiansStep.View:
        return t("Edit Guardians") as string;
      case EditGuardiansStep.Search:
        return t("Search Account") as string;
      default:
        return t("Edit Guardians") as string;
    }
  }, [step]);

  const rightBtnText: string = useMemo(() => {
    switch (step) {
      case EditGuardiansStep.View:
        return t("Cancel") as string;
      case EditGuardiansStep.Search:
        return t("Done") as string;
      default:
        return t("Cancel") as string;
    }
  }, [step]);

  const newGuardianList: string[] = useMemo(() => {
    return guardians.map((item) => item.name);
  }, [guardians]);

  useEffect(() => {
    if (newGuardianList.length < currentThreshold) {
      setCurrentThreshold(Math.max(newGuardianList.length, 2));
    }
  }, [currentThreshold, newGuardianList.length]);

  const reset = useCallback(() => {
    setStep(EditGuardiansStep.View);
    setAdding(false);
    setEditingGuardian(undefined);
    setChangedGuardianName(undefined);
  }, []);

  const updateGuardian = useCallback(
    (guardian: GuardianEditType, newName: string) => {
      const updatedGuardians = guardians.map((item) =>
        item.name === guardian.name ? { ...item, name: newName } : item
      );
      setGuardians(updatedGuardians);
    },
    [guardians]
  );

  const addGuardian = useCallback(
    (newName: string): boolean => {
      const isDuplicate = guardians.some((item) =>
        isSameName(item.name, newName)
      );

      if (isDuplicate) {
        toast({
          type: "error",
          text: t("Unable to add the same guardian multiple times"),
        });
        return false;
      }

      const newGuardian: GuardianEditType = {
        name: newName,
        rawName: undefined,
      };

      setGuardians([...guardians, newGuardian]);
      return true;
    },
    [guardians]
  );

  const removeGuardian = useCallback(
    (guardian: GuardianEditType) => {
      const updatedGuardians = guardians.filter(
        (item) => item.name !== guardian.name
      );
      setGuardians(updatedGuardians);
    },
    [guardians]
  );

  const handleRightBtnCallBack = useCallback(() => {
    if (step === EditGuardiansStep.View) {
      onClose();
      return;
    }
    if (step === EditGuardiansStep.Search) {
      if (adding && changedGuardianName) {
        const isSuccess = addGuardian(changedGuardianName);
        if (isSuccess) {
          reset();
        }
        return;
      }
      if (editingGuardian && changedGuardianName) {
        if (!isSameName(editingGuardian.name, changedGuardianName)) {
          updateGuardian(editingGuardian, changedGuardianName);
        }
        reset();
        return;
      }
      return;
    }
  }, [
    addGuardian,
    adding,
    changedGuardianName,
    editingGuardian,
    onClose,
    reset,
    step,
    updateGuardian,
  ]);

  const handleBack = useCallback(() => {
    if (step === EditGuardiansStep.View) {
      onClose();
      return;
    }
    if (step === EditGuardiansStep.Search) {
      reset();
      return;
    }
  }, [onClose, reset, step]);

  const handleEditClick = useCallback(
    (guardian: GuardianEditType) => {
      reset();
      setEditingGuardian(guardian);
      setStep(EditGuardiansStep.Search);
    },
    [reset]
  );

  const handleRemoveClick = useCallback(
    (guardian: GuardianEditType) => {
      if (guardians.length <= 2) {
        toast({
          type: "warning",
          text: t("You need to keep at least 2 guardians"),
        });
        return;
      }
      reset();
      removeGuardian(guardian);
    },
    [guardians.length, removeGuardian, reset]
  );

  const handleAddClick = useCallback(() => {
    if (guardians.length > 10) {
      toast({
        type: "warning",
        text: t("Currently, we don't support more than 10 guardians"),
      });
      return;
    }
    reset();
    setAdding(true);
    setStep(EditGuardiansStep.Search);
  }, [guardians.length, reset]);

  const hanldeNextClick = useCallback(() => {
    if (newGuardianList.length < 2) {
      toast({
        type: "warning",
        text: t("You need at least 2 guardians"),
      });
      return;
    }
    setShowThresHoldModal(true);
  }, [newGuardianList.length]);

  const handleThresholdChange = useCallback(
    (value: number) => {
      if (value <= newGuardianList.length && value >= 2) {
        setCurrentThreshold(value);
      }
    },
    [newGuardianList.length]
  );

  useEffect(() => {
    onInnerModalChange?.(showThresHoldModal || showPinModal);
  }, [showThresHoldModal, showPinModal, onInnerModalChange]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: () => {
      setShowPinModal(false);
      setShowThresHoldModal(false);
    },
    shouldClose: !loadingStore.isLoading,
  });

  const handleExecuteUpdateGuardian = useCallback(
    async (key: KontosKey) => {
      setShowPinModal(false);
      let sig = ""; // error catch
      let exp = 0; // error catch

      try {
        loadingStore.showLoading();
        const { signature, expiredAt } = await signForBe(
          userStore.accountName!,
          key,
          `${KontosQueryCli.nameAddress(
            userStore.accountName!
          )}::${"[]"}::[${newGuardianList.map((guardian) =>
            KontosQueryCli.nameAddress(guardian)
          )}]::${currentThreshold}`,
          COMMON_SIGN_EXPIREDAT
        );
        sig = signature;
        exp = expiredAt;
        await callUpdateAccountGuardian({
          account: userStore.accountName!,
          emails: [],
          guardians: newGuardianList,
          threshold: currentThreshold,
          expiredAt,
          signature,
        });
        toast({
          type: "success",
          text: t(
            "Successfully submitted your guardian change operation. This change will take effect later."
          ),
        });
        onSuccess();
      } catch (e) {
        console.log(e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to submit your guardian change operation");
        toast({
          type: "error",
          text: errorMessage,
        });
        uploadErrorOnce(
          userStore.accountName || "",
          "callUpdateAccountGuardian",
          {
            accountName: userStore.accountName || "",
            signData: `${KontosQueryCli.nameAddress(
              userStore.accountName!
            )}::${"[]"}::[${newGuardianList.map((guardian) =>
              KontosQueryCli.nameAddress(guardian)
            )}]::${currentThreshold}`,
            expiration: COMMON_SIGN_EXPIREDAT,
            expiredAt: exp,
            emails: [],
            guardians: newGuardianList,
            threshold: currentThreshold,
            signature: sig,
          },
          e
        );
      } finally {
        loadingStore.hideLoading();
      }
    },
    [currentThreshold, newGuardianList, onSuccess, userStore.accountName]
  );

  const handleNextSubmit = useCallback(async () => {
    if (
      stringArrayEqual(newGuardianList, rawGuardianList) &&
      currentThreshold === rawThreshold
    ) {
      toast({
        type: "info",
        text: t(
          "You don't need to update since it's the same guardian list and threshold"
        ),
      });
      return;
    }
    setShowPinModal(true);
  }, [currentThreshold, newGuardianList, rawGuardianList, rawThreshold]);

  return (
    <Container ref={wrapperRef}>
      <div className="header">
        <Header
          title={headerText}
          padding="8px 24px"
          callBack={handleBack}
          rightBtnText={rightBtnText}
          rightBtnCallBack={handleRightBtnCallBack}
        />
      </div>
      {step === EditGuardiansStep.View && (
        <EditGuardiansView
          rawGuardianList={rawGuardianList}
          guardians={guardians}
          onEdit={handleEditClick}
          onRemove={handleRemoveClick}
          onAdd={handleAddClick}
          onNext={hanldeNextClick}
        />
      )}
      {step === EditGuardiansStep.Search && (
        <EditGuardiansSearch
          rawAddress={editingGuardian?.name?.replaceAll(".os", "")}
          onDone={setChangedGuardianName}
        />
      )}

      <NoScrollerBarSheet
        isOpen={showThresHoldModal}
        onClose={() => setShowThresHoldModal(false)}
        mountPoint={domNode}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div
              style={{
                height: 330,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <SetUpThreshold
                submit={handleNextSubmit}
                cancel={() => setShowThresHoldModal(false)}
                maxThreshold={newGuardianList.length}
                minThreshold={2}
                currentThreshold={currentThreshold}
                setCurrentThreshold={handleThresholdChange}
                firstBtnText={t("Confirm") as string}
                text={
                  <span>
                    <Trans i18nKey={"trans-guardian-list-48"}>
                      *A <RedText style={{ color: "" }}>48-hour</RedText>{" "}
                      confirmation period is required to update the Guardian
                      list.
                    </Trans>
                  </span>
                }
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowThresHoldModal(false)} />
      </NoScrollerBarSheet>

      <NoScrollerBarSheet
        isOpen={showPinModal}
        onClose={() => setShowPinModal(false)}
        mountPoint={domNode}
        disableScrollLocking={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Unlock onSuccess={handleExecuteUpdateGuardian} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowPinModal(false)} />
      </NoScrollerBarSheet>
    </Container>
  );
};

export default EditGuardians;
