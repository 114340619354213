import Header from "@/components/common/header";
import { KontosInputV3 } from "@/components/input/KontosInputV3";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { getEmailInputStatus } from "../WaitingAuthorizeMail";
import { RecoverStatusIcon } from "../RecoverStatusIcon";
import { convertToLowercaseIfGmail, isValidGoogleEmail } from "@/utils/helper";
import { kecca256String } from "@/pages/newAuth/CreateAccount";
import { useCallback, useState } from "react";
import { WaitingEmailRightPart } from "../components/WaitingEmailRightPart";
import { TipsArea } from "@/components/tips-area/TipsArea";
import { KontosButton } from "@/components/button/KontosButton";
import { fontDescription, fontH7, fontMainText } from "@/style/style.global";
import toast from "@/components/toast/Toast";
import tipsIcon from "src/assets/icons/tips.svg";
import { callRecoverAccountByEmail } from "@/apis/aa-apis";
import { loadingStore } from "@/store/loadingStore";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 16px;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  padding: 10px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const Desc = styled.span`
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontMainText}
`;

const DescStrong = styled.span`
  color: var(--Kontos-Blue, #413dec);
`;

const EmailItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const EmailItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const EmailInput = styled(KontosInputV3)`
  background-color: var(--White, #fff);

  input {
    background-color: var(--White, #fff);
  }
`;

const PasteArea = styled.div`
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;

  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 16px;
  height: 190px;
  border-radius: 8px;
  background: var(--Deep-25, #f5f5f6);

  overflow-y: auto;
  color: var(--Deep-400, #80868f);
  ${fontDescription}
`;

const PasteFixedArea = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
`;

const PasteButton = styled(KontosButton)`
  position: absolute;
  right: 10px;
  bottom: 76px;
  padding: 8px 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--White, #fff);
  text-align: center;
  ${fontH7}

  border-radius: 99px;
  border: 4px solid var(--Deep-25, #f5f5f6);
  background: var(--Deep-800, #1a2535);
`;

const SubmitButton = styled(KontosButton)`
  position: absolute;
  bottom: 16px;
  width: calc(100% - 40px);
  left: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  accountName: string;
  userEmailArr: string[];
  emailGuardianHashes: string[];
  mailObj: any;
  onShowGuide: () => void;
  handleSendEmail: (address: string) => void;
  checkPubKey: (
    pubKey: string,
    remainApprovals: number,
    remainCallback: () => void
  ) => Promise<void>;
}

export const ManualRecovery: React.FC<IProps> = ({
  accountName,
  userEmailArr,
  emailGuardianHashes,
  mailObj,
  onShowGuide,
  handleSendEmail,
  checkPubKey,
}) => {
  const { t } = useTranslation();
  const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const handlePaste = useCallback(async () => {
    try {
      const clipText = await navigator.clipboard.readText();
      setCode(clipText);
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : t("Failed to read clipboard");
      toast({
        type: "error",
        text: errorMessage,
      });
    }
  }, [t]);

  const clearInput = useCallback(() => {
    setEmail("");
    setCode("");
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      loadingStore.showLoading();
      const { pubKey, remainApprovals } = await callRecoverAccountByEmail({
        account: accountName,
        originalEmail: code,
      });
      await checkPubKey(pubKey, remainApprovals, clearInput);
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : t("System Error");
      toast({
        type: "error",
        text: errorMessage,
      });
    } finally {
      loadingStore.hideLoading();
    }
  }, [accountName, checkPubKey, clearInput, code, t]);

  return (
    <Wrapper>
      <Header
        title={t("Manual Recovery")}
        rightBtnText={t("Guide")}
        rightBtnCallBack={onShowGuide}
        padding={"8px 24px"}
      />

      <Container>
        <MainContainer>
          <TipsIcon src={tipsIcon} alt="" />

          <Desc>
            <Trans i18nKey={"trans-manual-recovery-tip"}>
              Click the <DescStrong>"Guide"</DescStrong> button in the upper
              right corner to view the operation process.
            </Trans>
          </Desc>

          <EmailItemWrapper>
            <EmailItem>
              <EmailInput
                autoFocus
                inputStatus={getEmailInputStatus(email, emailGuardianHashes)}
                leftItem={
                  <RecoverStatusIcon
                    status={
                      !email ||
                      (isValidGoogleEmail(email) &&
                        emailGuardianHashes?.includes(kecca256String(email))) ||
                      isEmailFocused
                        ? "active"
                        : "error"
                    }
                  />
                }
                rightItem={
                  !email ||
                  (isValidGoogleEmail(email) &&
                    emailGuardianHashes?.includes(kecca256String(email))) ? (
                    <WaitingEmailRightPart
                      mailObj={mailObj}
                      index={0}
                      emailArr={[email]}
                      disabled={userEmailArr.length <= 0}
                      manualAddress={email}
                    />
                  ) : null
                }
                onChange={(e) => {
                  const str = convertToLowercaseIfGmail(e.target.value);
                  setEmail(str);
                }}
                onSubmit={() => handleSendEmail(email)}
                onFocus={() => {
                  setIsEmailFocused(true);
                }}
                onBlur={() => {
                  setIsEmailFocused(false);
                }}
                placeholder={t("Enter your security email")}
                value={email}
              />
              {!isEmailFocused &&
                email !== "" &&
                !isValidGoogleEmail(email) && (
                  <TipsArea status={"error"}>
                    {t(
                      "Please enter a valid Gmail address (e.g., yourname@gmail.com)."
                    )}
                  </TipsArea>
                )}
            </EmailItem>
          </EmailItemWrapper>

          <PasteArea>
            {code !== ""
              ? code
              : t("Please paste the email's Original Message here.")}
          </PasteArea>

          <PasteFixedArea>
            <PasteButton onClick={handlePaste}>{t("Paste")}</PasteButton>
          </PasteFixedArea>
        </MainContainer>

        <SubmitButton disabled={code === ""} onClick={handleSubmit}>
          {t("Submit")}
        </SubmitButton>
      </Container>
    </Wrapper>
  );
};
