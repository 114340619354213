import { ReactNode, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import {
  FullPageDisplayWrapper,
  ParitalDisplayWrapper,
  ParitalDisplayBox,
} from "src/components/wrapper/ReactiveWrapper";
import ToastContainer from "../toast/ToastContainer";
import NavigationBar from "../navi-bar/NavigationBar";
import { useStores } from "src/hooks/useStore";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { GlobalSheet } from "src/sheets/GlobalSheet";
import { NoAutoCloseSheetViews } from "src/store/SheetStore";

type ILayout = {
  children: ReactNode;
  toastId?: string;
};

const Layout = observer(({ children, toastId }: ILayout) => {
  const { uiStore, sheetStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;

  const handleMouseDownOutside = useCallback(() => {
    if (sheetStore.latestSheet) {
      if (!NoAutoCloseSheetViews.includes(sheetStore.latestSheet?.view)) {
        sheetStore.closeLatestSheet();
      }
    }
  }, [sheetStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    globalCallback: handleMouseDownOutside,
    shouldClose: true,
  });

  // This hook is used to update container(desktop) size when necessary
  useEffect(() => {
    if (uiStore.monitoringContainerSize === false) {
      return;
    }

    const element = wrapperRef.current;
    if (!element) return;

    // Function to handle size changes
    const updateSize = () => {
      uiStore.setContainerSize({
        width: element.offsetWidth,
        height: element.offsetHeight,
      });
    };

    // Initialize ResizeObserver
    let resizeObserver: ResizeObserver | null = null;

    if (uiStore.monitoringContainerSize) {
      resizeObserver = new ResizeObserver(() => {
        updateSize();
      });
      resizeObserver.observe(element);
      updateSize(); // Set initial dimensions
    }

    // Clean up the observer on unmount or when monitoring is stopped
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [uiStore, uiStore.monitoringContainerSize]);

  return (
    <div>
      {uiStore.isOverWidth ? (
        <ParitalDisplayWrapper>
          <ParitalDisplayBox ref={wrapperRef}>
            {children}
            <NavigationBar />
            <GlobalSheet mountPoint={domNode} />
          </ParitalDisplayBox>
          {toastId && <ToastContainer id={toastId} />}
        </ParitalDisplayWrapper>
      ) : (
        <FullPageDisplayWrapper ref={wrapperRef}>
          {children}
          <NavigationBar />
          <GlobalSheet mountPoint={domNode} />
        </FullPageDisplayWrapper>
      )}
    </div>
  );
});

export default Layout;

export const NoNavLayout = ({ children, toastId }: ILayout) => {
  const { uiStore } = useStores();

  return (
    <div>
      {uiStore.isOverWidth ? (
        <ParitalDisplayWrapper>
          <ParitalDisplayBox style={{}}>{children}</ParitalDisplayBox>
          {toastId && <ToastContainer id={toastId} />}
        </ParitalDisplayWrapper>
      ) : (
        <FullPageDisplayWrapper style={{}}>{children}</FullPageDisplayWrapper>
      )}
    </div>
  );
};
