import { makeAutoObservable } from "mobx";
import { FtAsset, FtAssetBase } from "src/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import { handleError } from "src/pages/contract-interaction/executableMethods";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import React, { memo, ReactNode, useContext, useMemo } from "react";

export type PaymentMethod = "plan" | "receive" | "otc";

export interface PaymentInitData {
  method: PaymentMethod;
  targetFtAsset: FtAsset;
  targetFtAssetQuantity: KontosNumber;
  targetFtAssetValue: KontosNumber;
  maxAvailableUsd: KontosNumber;
  fetchAndSetTaskData: (
    toQuoteAsset?: FtAssetBase
  ) => Promise<RespTaskPayment | undefined>;
  receiveConfig?: {
    enable: boolean;
    asset?: FtAsset;
  };
  otcBuyConfig?: {
    enable: boolean;
    asset?: FtAsset;
  };
}

export class PaymentStore {
  method: PaymentMethod = "plan";
  allowedMethods: PaymentMethod[] = [];
  toReceiveFtAsset?: FtAsset;
  toOtcBuyFtAsset?: FtAsset;
  targetFtAsset?: FtAsset;
  targetFtAssetQuantity?: KontosNumber;
  targetFtAssetValueInUsd?: KontosNumber;
  isCustomPlan: boolean = false;
  maxAvailableUsd: KontosNumber = new KontosNumber(0);
  fetchAndSetTaskData?: (
    toQuoteAsset?: FtAssetBase
  ) => Promise<RespTaskPayment | undefined>;

  constructByPlan = () => {
    this.method = "plan";
    this.allowedMethods.push("plan");
  };

  constructByOthers = (data: PaymentInitData) => {
    this.method = data.method;
    this.fetchAndSetTaskData = data.fetchAndSetTaskData;
    this.targetFtAsset = data.targetFtAsset;
    this.targetFtAssetQuantity = data.targetFtAssetQuantity;
    this.targetFtAssetValueInUsd = data.targetFtAssetValue;
    this.maxAvailableUsd = data.maxAvailableUsd;
    if (data.receiveConfig?.enable) {
      this.toReceiveFtAsset = data.receiveConfig.asset;
      this.allowedMethods.push("receive");
    }
    if (data.otcBuyConfig?.enable) {
      this.toOtcBuyFtAsset = data.otcBuyConfig.asset;
      this.allowedMethods.push("otc");
    }
  };

  constructor(data: { isCustomPlan?: boolean; initData?: PaymentInitData }) {
    const { isCustomPlan, initData } = data;
    this.isCustomPlan = isCustomPlan || true; // Set true by default
    if (initData) {
      this.constructByOthers(initData);
    } else {
      // Set plan by default
      this.constructByPlan();
    }
    makeAutoObservable(this, {}, { autoBind: true });
  }

  switchToPlanMethod = () => {
    this.allowedMethods = ["plan"];
    this.method = "plan";
  };

  setMethod = (method: PaymentMethod) => {
    this.method = method;
  };

  setToReceiveFtAsset = (asset?: FtAsset) => {
    console.log("asset", asset);
    this.toReceiveFtAsset = asset;
    // Due to the consistent range of values, it is currently allowed to synchronously change receive/otc assets 240910@Alex
    this.toOtcBuyFtAsset = asset;
  };

  setToOtcBuyFtAsset = (asset?: FtAsset) => {
    this.toOtcBuyFtAsset = asset;
    // Due to the consistent range of values, it is currently allowed to synchronously change receive/otc assets 240910@Alex
    this.toReceiveFtAsset = asset;
  };

  wrappedFetchAndSetTaskData = async (
    toQuoteAsset?: FtAssetBase
  ): Promise<RespTaskPayment | undefined> => {
    if (this.fetchAndSetTaskData) {
      try {
        return await this.fetchAndSetTaskData(toQuoteAsset);
      } catch (e) {
        handleError(e);
      }
    }
  };
}

export const PaymentContext: React.Context<PaymentStore | undefined> =
  React.createContext<PaymentStore | undefined>(undefined);

export const PaymentProvider = memo(
  ({
    isCustomPlan,
    initData,
    children,
  }: {
    isCustomPlan?: boolean;
    initData?: PaymentInitData;
    children?: ReactNode;
  }) => {
    const paymentStore = useMemo(
      () => new PaymentStore({ isCustomPlan, initData }),
      [isCustomPlan, initData]
    );

    return (
      <PaymentContext.Provider value={paymentStore}>
        {children}
      </PaymentContext.Provider>
    );
  }
);

export const usePaymentStore = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePaymentStore must be used within a PaymentProvider");
  }
  return context;
};
