import styled from "styled-components";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { EbActionItem } from "./EbActionItem";
import Divider from "src/components/divider/Divider";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
  EB_ACTION_STATUS_PENDING,
  EB_ACTION_STATUS_SUCCESS,
} from "src/apis/types/energyBoostingTypes";

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);

  .eb-action-box-header {
    user-select: none;
    margin: 20px 0 20px 0;
    padding: 0 16px 0 15.6px;
  }

  .eb-action-box-item {
    margin: 0 0 12px 0px;
    padding: 0 10px 0 15.6px;
  }
`;

const ActionsContainer = styled(motion.div)`
  width: 100%;
`;

const RatioText = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
`;

const NormalText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
`;

const TotalText = styled(NormalText)`
  margin-right: 10px;
`;

const getRightText = (x: string, y: string) => {
  if (x !== "0" && y === "") {
    return <NormalText>{x}</NormalText>;
  }
  if ((x === "0" || x === "1") && y === "1") {
    return <RatioText>{x + "/1"}</RatioText>;
  }
  if (x !== "0" && y === "1") {
    return (
      <>
        <TotalText>{"Total: " + x}</TotalText>
        <RatioText>{"1/1"}</RatioText>
      </>
    );
  }
  return null;
};

interface Props {
  actions: ebTypes.Actions;
  className?: string;
  onAuth: (authType: number) => void;
  forcedFold?: boolean;
  discordPending?: boolean;
  twitterPending?: boolean;
}

export const EbActionBox: React.FC<Props> = ({
  actions,
  className,
  onAuth,
  forcedFold = false,
  discordPending,
  twitterPending,
}) => {
  const { t } = useTranslation();
  const [fold, setFold] = useState<boolean>(
    () =>
      forcedFold ||
      actions?.actionItems?.every(
        (item) => item?.actionStatus === EB_ACTION_STATUS_SUCCESS
      )
  );

  const completionStatus: number =
    actions?.actionItems?.filter(
      (item) => item.actionStatus === EB_ACTION_STATUS_SUCCESS
    )?.length === actions?.actionItems?.length
      ? EB_ACTION_STATUS_SUCCESS
      : EB_ACTION_STATUS_PENDING;
  // e.g. 1/3
  const completionRatio: string =
    actions?.actionItems
      ?.filter((item) => item.actionStatus === EB_ACTION_STATUS_SUCCESS)
      ?.length?.toString() +
    "/" +
    actions?.actionItems?.length?.toString();

  const getBtnText = useCallback(
    (actionType: number, actionStatus: number) => {
      if (actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER) {
        if (actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS) {
          return t("Finished");
        }
        return t("Follow");
      }
      if (actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD) {
        if (actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS) {
          return t("Finished");
        }
        return t("Join Now");
      }
      if (actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_BYBIT) {
        if (actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS) {
          return t("Finished");
        }
        // return t("Join Now");
        return t("Ended");
      }
      return undefined;
    },
    [t]
  );

  const handleActionClick = useCallback(
    (actionType: number) => {
      if (
        actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER ||
        actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD ||
        actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_BYBIT
      ) {
        onAuth(actionType);
      }
    },
    [onAuth]
  );

  const isActionPending = useCallback(
    (item: ebTypes.ActionItem) => {
      switch (item.actionType) {
        case ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER:
          return item.actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS
            ? false
            : twitterPending;
        case ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD:
          return item.actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS
            ? false
            : discordPending;
        default:
          return false;
      }
    },
    [discordPending, twitterPending]
  );

  return (
    <Container
      className={className}
      // TODO: eb anime
      // initial={{ y: 100, opacity: 0 }}
      // animate={{ y: 0, opacity: 1 }}
      // transition={{ type: "spring" }}
    >
      {/* Header Item*/}
      <EbActionItem
        className="eb-action-box-header"
        isTitle={true}
        status={completionStatus}
        desc={actions.categoryDesc + " " + completionRatio}
        isFold={fold}
        onGlobalClick={() => {
          setFold((prev) => !prev);
        }}
        needStar={actions.category === ebTypes.EB_KEY_ACTION_CATEGORY}
      />

      {!fold && (
        <ActionsContainer
        // initial={{ y: -100, opacity: 0 }}
        // animate={{ y: 0, opacity: 1 }}
        // transition={{ type: "tween", ease: "linear", duration: 0.2 }}
        >
          <Divider isDashed={false} top={0} bottom={20} color="#EDEEF1" />

          {actions?.actionItems?.map((item) => (
            <EbActionItem
              key={item.actionTypeDesc}
              className="eb-action-box-item"
              isTitle={false}
              status={item?.actionStatus}
              desc={item.actionTypeDesc}
              btnText={getBtnText(item.actionType, item.actionStatus)}
              btnDisabled={
                item.actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS ||
                item.actionType === ebTypes.EB_SOCIAL_ACTION_TYPE_BYBIT // Bybit event ends @241210
              }
              btnPending={isActionPending(item)}
              onClick={() => handleActionClick(item.actionType)}
              rightText={getRightText(item?.outputX || "", item?.outputY || "")}
            />
          ))}
        </ActionsContainer>
      )}
    </Container>
  );
};
