import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, InputPassword } from "../../components/input/Input";
import BackIcon from "../../assets/images/main-back-icon.svg";
import KontosIcon from "../../assets/images/kontos-icon.svg";
import LoadingIcon from "../../assets/images/main-loading.svg";
import KontosIconSeleted from "../../assets/images/kontos-icon-seleted.svg";
import ErrorTipsIcon from "../../assets/images/error-tips-icon.svg";
import SuccessIcon from "../../assets/images/success-icon.svg";
import PendingIcon from "../../assets/images/pending-icon.svg";
import SuccessIcon1 from "../../assets/images/success.svg";
import { InfoTips } from "./InfoTips";
import { KontosQueryCli } from "@zkkontos/kontos-sdk/src/core/kontosQuery";
import toast from "../../components/toast/Toast";
import ERC20 from "../../assets/abis/ERC20.json";
import { ethers } from "ethers";
import { gqlGetGuardiansByAddress } from "../../network/apollo/query";
import { KONTOS_GATE, KONTOS_JSON_RPC, KONTOS_URL } from "../../config";
import {
  getDefaultClientPolymorphism,
  KontosKeyHelper,
} from "@zkkontos/kontos-sdk";
import { getAS } from "src/utils/currency/reactive";
import { GUARDIAN_TIPS } from "./RegisterKontos";
import { DefalutButton } from "./style";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import {
  openUrl,
  isWasmLoaded,
  waitForWasmLoad,
  getNoSuffixKontosName,
} from "../../utils/helper";
import { ROUTE_HOME } from "src/router/router-config";
import { PINCodeInput } from "src/components/images/PINCodeInput";
import { getBtnText } from "src/router/router-bot";
import { useStores } from "src/hooks/useStore";
import { callRecoverAccountBySeedProof } from "@/apis/aa-apis";
import { Account } from "@/type/zkkontos";
import { fetchAccount } from "@/service/account-service";

export type GuardianState = {
  name: string;
  state: string;
};
type Props = {
  onBack(): void;
};
export const PENDING = "Pending";
export const CONFIRMED = "Confirmed";
export const OldRecoverAccount: React.FC<Props> = observer(({ onBack }) => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [password, setPassword] = useState("");
  const [name, setName] = useState(searchParams.get("name") || "");
  const setNameTrimed = useCallback((newValue: string) => {
    setName(newValue.trim());
  }, []);
  const [isExitOnChain, setIsExitOnChain] = useState<boolean | null>(null);
  const [recoveryAccountPending, setRecoveryAccountPending] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [account, setAccount] = useState<Account>();
  const [guardiansState, setGuardiansState] = useState<GuardianState[]>([]);
  const [step, setStep] = useState(0);
  const [actionThreshold, setActionThreshold] = useState(1);
  const [inputBlur, setInputBlur] = useState(false);
  // const [inputPasswordBlur, setInputPasswordBlur] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  // const [isRequired, setIsRequired] = useState(false);
  const [requiredList, setRequiredList] = useState<any[]>([]);
  const [insufficient, setInsufficient] = useState(false);
  const [hasSeed, setHasSeed] = useState(false);
  const [seed, setSeed] = useState("");
  const [newKey, setNewKey] = useState("");
  const [showForgetPasswordNext, setShowForgetPasswordNext] = useState(false);
  const [accountInfo, setAccountInfo] = useState<Account | null>(null);

  //new
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showAccountExistingModal, setShowAccountExistingModal] =
    useState(false);
  const [hasGuardian, setHasGuardian] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    checkSeed();
  }, []);

  // If PIN is already set, skip PIN setup
  const checkSeed = async () => {
    const storageKey = userStore.getStorageKontosKey();
    const seed = userStore.getPin();
    if (storageKey && seed) {
      setSeed(seed);
      setHasSeed(true);
      setStep(1);
    } else {
      setStep(-1);
    }
  };

  const unlockConfirm = useMemo(() => {
    return seed !== "" && seed.length === 6;
  }, [seed]);

  // dynamic loading wasm
  useEffect(() => {
    if (!isWasmLoaded()) require("src/wasm_exec.js");
  }, []);

  useEffect(() => {
    if (step === 99) {
      // handleCreateNewKey()
    }
  }, [step]);

  useEffect(() => {
    // wait for guardian approve
    // if (
    //   step === 1 &&
    //   statusKeeper.accountsRecovering?.includes(name) &&
    //   inputBlur
    // ) {
    //   setStep(3);
    //   setName(name);
    // }
    // if (step === 3 && statusKeeper.accountsRecovering?.includes(name)) {
    //   handleCheckName()?.then((account) => {
    //     if (account) {
    //       //waiting guardian, only frist show loading
    //       getGuardiansInitState(account);
    //       setInterval(() => {
    //         getGuardiansState(account);
    //       }, 5000);
    //     }
    //   });
    // }

    if (step === 4) {
      userStore.updateUnlockAmount(0);
    }
  }, [name, step, inputBlur, userStore]);

  // recovery successful remove recovering status
  const removeRecovering = () => {
    if (userStore.accountsRecovering?.includes(name)) {
      const list = userStore.accountsRecovering?.filter(
        (item) => item !== name
      );
      userStore.updateAccountsRecovering(list);
    }
  };

  const UNLOCK_TIPS = [
    {
      title: t("The Unlock PIN Code"),
      content: [
        t("The code is only used to unlock your wallet on this device"),
        t("The code consists of 6 digits only"),
      ],
    },
  ];

  const handleCheckName = async () => {
    if (!name) return;
    try {
      setIsExitOnChain(null);
      setNameLoading(true);
      const account = await fetchAccount(name);
      const { blockChainAccounts } = account;
      console.log(account);
      setAccountInfo(account);
      setNameLoading(false);
      setIsExitOnChain(true);
      setAccount(account);
      setActionThreshold(account.actionThreshold);
      // if (blockchainAccounts && Object.keys(blockchainAccounts).length > 0) {
      //   setIsRequired(true);
      // } else {
      //   setIsRequired(false);
      // }
      return account;
    } catch (e) {
      console.warn(e);
      setIsExitOnChain(false);
      setNameLoading(false);
    }
  };

  const handleRecover = async (name: string, password: string) => {
    setRecoveryAccountPending(true);
    try {
      await waitForWasmLoad(15000, 1000);
      const key = await KontosKeyHelper.createKontosKey(
        getNoSuffixKontosName(name),
        seed
      );
      const proof =
        await getDefaultClientPolymorphism().wasmGenerateSigmaProofWithSeed(
          password
        );

      await callRecoverAccountBySeedProof({
        account: getNoSuffixKontosName(name),
        newRecoveryPubKey: key.pubKeyData.compressedPubKey,
        proof,
      });

      let _hasGuardian = false;
      await userStore.updateKey(key, seed);
      if (account && account.guardians && account.guardians.length) {
        userStore.updateAccountsRecovering([
          ...(userStore.accountsRecovering || []),
          name,
        ]);
        // localKeeper.switchAccount(0);
        // setStep(3);
        _hasGuardian = true;
        setHasGuardian(_hasGuardian);
        // return;
      }

      setStep(4);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message.includes("invalid proof")) {
          toast({
            text: t("The recovery password you entered is incorrect"),
            type: "error",
          });
        } else {
          toast({ text: `recover fail:${e}`, type: "error" });
        }
      } else {
        toast({ text: "invalid password", type: "error" });
      }
      console.warn(e);
    } finally {
      setRecoveryAccountPending(false);
    }
  };

  const getGuardiansInitState = async (account: Account) => {
    if (!account) return;
    setLoading(true);
    const res = await gqlGetGuardiansByAddress(
      KontosQueryCli.nameAddress(account.name)
    ).catch(() => ({ data: { guardian: [] } }));
    setLoading(false);
    const list = res.data.guardian;
    if (list.length) {
      const init: GuardianState[] = list.map((item: any) => {
        return {
          name: item.guardian_account.name,
          state: PENDING,
        };
      });
      setGuardiansState(init);
    }
  };

  const handleNameBlur = useCallback(
    async (str: string) => {
      setInputBlur(true);
      const regex = /^[a-z0-9_]{4,}$/;
      if (str?.length <= 3) {
        setNameError("At least 4 Characters Required.");
        return;
      }
      if (str?.length >= 65) {
        setNameError("No more than 64 characters.");
        return;
      }
      if (!regex.test(str)) {
        setNameError("Name verification failed.");
        return;
      }
      setNameError("");
      await handleCheckName();
    },
    [nameError, name]
  );
  const handlePasswordBlur = (str: string) => {
    // setInputPasswordBlur(true);
    let errText = "";
    if (str?.length < 8) {
      errText += t("At least 8 characters required") + "<br />";
    }
    if (str?.length > 32) {
      errText += t("No more than 32 characters") + "<br />";
    }
    if (!/\d/.test(str)) {
      errText += t("Must contain at least one digit") + "<br />";
    }
    if (!/(?=.*?[A-Z])/.test(str) || !/(?=.*?[a-z])/.test(str)) {
      errText += t("Must contain uppercase and lowercase") + "<br />";
    }
    if (!/(?=.*?[#?!@$%^&_*-\.])/.test(str)) {
      errText += t("Must contain symbols (#@$!%_*?-.&)") + "<br />";
    }
    setPasswordError(errText);
  };

  const getBalance = async (address: string) => {
    const provider = await new ethers.providers.JsonRpcProvider(
      KONTOS_JSON_RPC
    );
    const contract = new ethers.Contract(address, ERC20, provider);
    const kusdBalance = await contract.balanceOf(
      KontosQueryCli.nameAddress(name)
    );
    return ethers.utils.formatEther(kusdBalance);
  };

  const handleGoDeposit = () => {
    openUrl(KONTOS_GATE);
  };

  const getRecoveryIcon = () => {
    if (nameLoading && name && inputBlur) {
      return <img src={LoadingIcon} className="ico icoLoading" alt="" />;
    }
    if (name && inputBlur && nameError) {
      return <img src={ErrorTipsIcon} className="ico" alt="" />;
    }
    if (isExitOnChain === true && name && inputBlur) {
      return <img src={SuccessIcon1} className="ico icoSuccess" alt="" />;
    }
    if (isExitOnChain === false && name && inputBlur) {
      return <img src={ErrorTipsIcon} className="ico" alt="" />;
    }
    if (!inputBlur && name) {
      return <img src={KontosIconSeleted} className="ico" alt="" />;
    }
    return <img src={KontosIcon} className="ico" alt="" />;
  };

  const getPasswordErrText = useCallback(() => {
    if (passwordError) {
      return <div className={"errText"}>{passwordError}</div>;
    }
  }, [passwordError]);

  const getNameErrText = useCallback(() => {
    if (name && inputBlur && nameError) {
      return <div className="nameErr">{nameError}</div>;
    }
    if (isExitOnChain === false && name && inputBlur) {
      return <div className={"nameErr"}>Account name does not exist</div>;
    }
  }, [isExitOnChain, name, inputBlur, nameError]);

  const recoveryStep1Confirm = useMemo(() => {
    return (
      name !== "" &&
      isExitOnChain === true &&
      password !== "" &&
      !nameError &&
      !passwordError
    );
  }, [name, password, isExitOnChain, nameError, passwordError]);

  const recoveryStep2Confirm = useMemo(() => {
    const total = guardiansState.reduce(
      (acc, cur) => (cur.state === CONFIRMED ? acc + 1 : acc),
      0
    );
    const res = total >= actionThreshold;
    if (res) {
      removeRecovering();
    }
    return res;
  }, [actionThreshold, guardiansState]);

  const recoveryThreshold = useMemo(() => {
    const total = guardiansState.reduce(
      (acc, cur) => (cur.state === CONFIRMED ? acc + 1 : acc),
      0
    );
    return actionThreshold - total;
  }, [actionThreshold, guardiansState]);

  const isRecoveryNameErr = useMemo((): boolean => {
    if (inputBlur && nameError && name) return true;
    if (isExitOnChain === false && inputBlur && name) return true;
    return false;
  }, [isExitOnChain, nameError, inputBlur, name]);

  return (
    <>
      {step === -1 && !hasSeed && (
        <div className="content content1">
          <img
            className={"logoIcoSmall"}
            src="/static/kontos-app.svg"
            alt="kontos-app"
          />
          <div className={"info"}>
            {t("Set Unlock PIN Code")}
            <div className={"infoIco"}>
              <InfoTips data={UNLOCK_TIPS} />
            </div>
          </div>
          <div className="input-wrapper ">
            <PINCodeInput onPINChange={setSeed} />
          </div>
          <div className="btns">
            <DefalutButton
              fullWidth
              size="large"
              text={t("Continue")}
              disabled={!unlockConfirm}
              onClick={() => {
                setStep(1);
              }}
            />
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="content content1">
          {!hasSeed && (
            <img
              src={BackIcon}
              className="back"
              alt=""
              onClick={() => {
                setStep(-1);
                setSeed("");
              }}
            />
          )}
          <img
            className={"logoIcoSmall"}
            src="/static/kontos-app.svg"
            alt="kontos-app"
          />
          <div className={"info"}>
            {t("Recover Wallet")}
            {/* {windowWidth >= 600 && (
              <div className={"infoIco"}>
                <InfoTips data={ADDRESS_TIPS} />
              </div>
            )} */}
          </div>
          <div
            className={`addressInput ${isRecoveryNameErr && "addressInputErr"}`}
          >
            <Input
              onChange={(e) => {
                setNameTrimed(e?.toLowerCase());
              }}
              onFocus={() => {
                setInputBlur(false);
              }}
              onBlur={() => handleNameBlur(name)}
              value={name}
              placeHolder={t("Enter Your Username")}
            />
            {getRecoveryIcon()}
            <span>.os</span>
            {getNameErrText()}
          </div>
          <InputPassword
            onChange={(e) => {
              setPassword(e);
              handlePasswordBlur(e);
            }}
            // onFocus={() => {
            //   setInputPasswordBlur(false);
            // }}
            // onBlur={() => handlePasswordBlur(password)}
            value={password}
            placeHolder={t("Password")}
          />
          {getPasswordErrText()}
          {/* {isRequired ? (
            <div className="btns">
              <DefalutButton
                fullWidth
                disabled={!recoveryStep1Confirm}
                size="large"
                loading={paymentLoading}
                text={`${paymentLoading ? t("Confirming") : t("Continue")}`}
                onClick={handleRecoverPre}
              />
            </div>
          ) : (
            <div className="btns">
              <DefalutButton
                fullWidth
                disabled={!recoveryStep1Confirm}
                size="large"
                loading={recoveryAccountPending}
                text={`${
                  recoveryAccountPending ? t("Continuing") : t("Continue")
                }`}
                onClick={() => handleRecover(name, password)}
              />
            </div>
          )} */}
          <div className="btns">
            <DefalutButton
              fullWidth
              disabled={!recoveryStep1Confirm}
              size="large"
              loading={recoveryAccountPending}
              text={`${
                recoveryAccountPending ? t("Continuing") : t("Continue")
              }`}
              onClick={() => {
                if (
                  (accountInfo?.emailGuardians?.length &&
                    accountInfo?.emailGuardians?.length > 0) ||
                  (accountInfo?.guardians && accountInfo?.guardians?.length > 0)
                ) {
                  toast({
                    text: t(
                      "Your account needs to be recovered via email or guardians."
                    ),
                    type: "error",
                  });
                } else {
                  handleRecover(name, password);
                }
              }}
            />
          </div>
          {/* {name && !!account?.guardians.length &&
           <div className="text-butntton" onClick={() => setStep(99)}>{t('Forget password')} ?</div>} */}
        </div>
      )}
      {step === 2 && (
        <div className={"content content1"}>
          <img
            src={BackIcon}
            className="back"
            alt=""
            onClick={() => setStep(1)}
          />
          <img
            className={"logoIcoSmall"}
            src="/static/kontos-app.svg"
            alt="kontos-app"
          />
          <div className={"info"}>{t("Recover Wallet")}</div>
          <div className="payment-container">
            <div className="head">
              <span>{t("kToken insufficient")}?</span>
              <div className="btn" onClick={handleGoDeposit}>
                {t("Purchase")}
              </div>
            </div>
            <div className="desc">
              {t(
                "For restoring your account, you need to pay a certain amount of fees for multi-chain account contract calls."
              )}
            </div>
            {requiredList.length > 0 && (
              <>
                <div className="payment-list">
                  <div className="list">
                    {requiredList.map((item) => (
                      <div className="payment-item" key={item.address}>
                        <img
                          src={item.image}
                          className="chain-icon"
                          alt="chain-icon"
                        />
                        <span>{item.name}</span>
                        <span
                          className={`amount ${
                            item.amount > item.balance && "error"
                          }`}
                        >
                          -{item.amount} {item.symbol}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="payment-total">
                  {requiredList.map((item) => (
                    <div className="list" key={item.address}>
                      <div className="list-item">
                        <img
                          src={item.tokenImage}
                          className="chain-icon"
                          alt="token-icon"
                        />
                        <div className="info-container">
                          <span>{item.symbol}</span>
                          <span className="info-desc">{t("Kontos Chain")}</span>
                        </div>
                        <div className="info-container info-container-2">
                          <span className="total-amount">
                            -{item.amount} {item.symbol}
                          </span>
                          <span className="info-desc end">
                            {getAS(item.amount * item.tokenPrice)}
                          </span>
                        </div>
                      </div>
                      <div className="payment-balance">
                        {t("My balance: ")}
                        {item.balance} {item.symbol}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {insufficient && (
              <div className="payment-err">
                {t("Insufficient balance, please purchase enough kUSD first")}
              </div>
            )}
          </div>
          <DefalutButton
            onClick={() => handleRecover(name, password)}
            loading={recoveryAccountPending}
            size="large"
            disabled={insufficient}
            fullWidth
            text={`${
              recoveryAccountPending
                ? t("Confirming Payment")
                : t("Confirm Payment")
            }`}
          />
        </div>
      )}
      {step === 3 && (
        <div className={"content content1"}>
          <img
            className={"logoIcoSmall"}
            src="/static/kontos-app.svg"
            alt="kontos-app"
          />
          <div className={"info"}>
            {t("Wait for Guardian's Permission")}
            <div className={"infoIco"}>
              <InfoTips data={GUARDIAN_TIPS} hover />
            </div>
          </div>
          {loading ? (
            <img src={LoadingIcon} className="main-loading" srcSet="" />
          ) : (
            <div className={"accountList"}>
              {guardiansState.length > 0 &&
                guardiansState.map((item) => (
                  <div className={"list"} key={item.name}>
                    <div>
                      <img
                        className={"headImg"}
                        src="/static/kontos-app.svg"
                        alt="kontos-app"
                      />
                      <span className={"accountName"}>{item.name}.os</span>
                    </div>
                    {item.state === CONFIRMED ? (
                      <div>
                        <span className={"successText"}>{t("Confirmed")}</span>
                        <img src={SuccessIcon} className="statusIco" alt="" />
                      </div>
                    ) : (
                      <div>
                        <span className={"pendingText"}>{t("Pending")}</span>
                        <img src={PendingIcon} className="statusIco" alt="" />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
          {recoveryStep2Confirm ? (
            <DefalutButton
              onClick={async () => {
                setStep(4);
              }}
              size="large"
              fullWidth
              text={t("Continue")}
            />
          ) : (
            <DefalutButton
              disabled
              size="large"
              fullWidth
              text={t("Need {{recoveryThreshold}} More Confirmation", {
                recoveryThreshold,
              })}
            />
          )}
        </div>
      )}
      {step === 4 && (
        <div className={"content content2"}>
          <img
            className={"logoIcoSmall"}
            src="/static/kontos-app.svg"
            alt="kontos-app"
          />
          {hasGuardian ? (
            <div className={"info"}>
              {t(
                "Please inform your guardians to approve the restoration of your account."
              )}
            </div>
          ) : (
            <div className={"info"}>{t("Restore Successfully!")}</div>
          )}

          <img src={SuccessIcon1} className={"importSuccess"} alt="" />
          <div className="btns">
            <DefalutButton
              onClick={async () => {
                // backToBot(
                //   statusKeeper.kontosCtx?.guild,
                //   statusKeeper.kontosCtx?.channel
                // );
                // onBack();
                await userStore.restore(true);
                if (userStore.routeAfterAuth) {
                  navigate(userStore.routeAfterAuth);
                  userStore.updateRouteAfterAuth(undefined);
                } else {
                  navigate(ROUTE_HOME);
                }
              }}
              text={getBtnText()}
              fullWidth
              size="large"
            />
          </div>
        </div>
      )}
    </>
  );
});
