import { fontDescription } from "@/style/style.global";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import playIcon from "src/assets/images/recovery/play.svg";
import pauseIcon from "src/assets/images/recovery/pause.svg";
import loadingIcon from "src/assets/images/recovery/loading.svg";
import toast from "@/components/toast/Toast";
import placeholderImg from "src/assets/images/recovery/placeholder.png";
import { isMobileDevice } from "@/utils/helper";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 16px;
`;

const Shadow = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0.35) 100%
  );
`;

const PlaceHolderImage = styled.img`
  width: 100%;
  height: auto;
`;

const DurationText = styled.span`
  position: absolute;
  bottom: 16px;
  right: 16px;

  color: #fff;
  text-align: right;
  ${fontDescription}
`;

const PauseButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  width: 99%;
  height: 99%;
  z-index: 2;
`;

const PauseIcon = styled.img`
  width: 80px;
  height: 80px;
`;

const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  width: 99%;
  height: 99%;
  z-index: 2;
`;

const PlayIcon = styled.img`
  width: 80px;
  height: 80px;
`;

const LoadingIcon = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  will-change: transform;
  @keyframes rotation {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  animation: rotation 1s linear infinite;
`;

const Video = styled.video`
  border-radius: 16px;
  width: 100%;
  height: auto;
  cursor: pointer;
`;

export const GuideVideo: React.FC = () => {
  const { t } = useTranslation();
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isMouseInVideo, setIsMouseInVideo] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string | null>(null);

  const handleDownloadAndPlay = async () => {
    try {
      setIsDownloading(true);
      const response = await fetch("/videos/manual-recovery-guide.mp4", {
        credentials: "same-origin",
      });
      if (!response.ok) {
        throw new Error(t("Video download failed"));
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
    } catch (e) {
      console.error("Error downloading video:", e);
      const errorMessage =
        e instanceof Error ? e.message : t("Video download failed");
      toast({
        type: "error",
        text: errorMessage,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        const seconds = Math.floor(videoRef.current.currentTime % 60);
        const formattedSeconds = String(seconds).padStart(2, "0");
        setCurrentTime(formattedSeconds);
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [videoUrl]);

  return (
    <Container
      onMouseEnter={() => !isMobileDevice() && setIsMouseInVideo(true)}
      onMouseLeave={() => !isMobileDevice() && setIsMouseInVideo(false)}
    >
      {typeof videoUrl === "string" ? (
        <>
          <Video
            ref={videoRef}
            src={videoUrl}
            autoPlay
            muted
            playsInline
            onClick={handleVideoClick}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={() => {
              setVideoUrl(null);
            }}
          />
          {(isMouseInVideo || !isPlaying) && <Shadow />}
          {isPlaying && isMouseInVideo && (
            <PauseButton onClick={handleVideoClick}>
              <PauseIcon src={pauseIcon} alt={t("Pause")} />
            </PauseButton>
          )}
          {!isPlaying && (
            <>
              <DurationText>{`00:${currentTime}`}</DurationText>
              <PlayButton onClick={handleVideoClick}>
                <PlayIcon src={playIcon} alt={t("Play")} />
              </PlayButton>
            </>
          )}
        </>
      ) : (
        <>
          <PlaceHolderImage src={placeholderImg} alt={t("PlaceHolder")} />
          <DurationText>00:28</DurationText>
          {isDownloading ? (
            <LoadingIcon src={loadingIcon} alt={t("Loading")} />
          ) : (
            <PlayButton onClick={handleDownloadAndPlay}>
              <PlayIcon src={playIcon} alt={t("Play")} />
            </PlayButton>
          )}
        </>
      )}
    </Container>
  );
};
