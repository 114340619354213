export const API = {
  maa: {
    account: "/api/v1/maa/account",
    searchAccountNames: "/api/v1/maa/searchaccountnames",
    accountThresholdActions: "/api/v1/maa/accountthresholdactions",
    wardsThresholdActions: "/api/v1/maa/wardsthresholdactions",
    accountNewPubKeyAction: "/api/v1/maa/accountnewpubkeyaction",
    accountNewPubKeyActionProcess: "/api/v1/maa/accountnewpubkeyactionprocess",
    nonce: "/api/v1/maa/nonce",
    emailRegisterCode: "/api/v1/maa/emailregistercode",
    emailRegisterVerify: "/api/v1/maa/emailregisterverify",
    registerAccount: "/api/v1/maa/registeraccount",
    updateAccountGuardian: "/api/v1/maa/updateaccountguardian",
    recoverAccountBySeedProof: "/api/v1/maa/recoveraccountbyseedproof",
    recoverAccountByGuardian: "/api/v1/maa/recoveraccountbyguardian",
    revertUpdateAccountGuardian: "/api/v1/maa/revertupdateaccountguardian",
    accountWards: "/api/v1/maa/accountwards",
    recoverAccountByEmail: "/api/v1/maa/recoveraccountbyemail",
  },
  masset: {
    ftasset: "/api/v1/masset/ftasset",
    ftassetDetail: "/api/v1/asset/ftassetdetail",
    syncSingleBalance: "/api/v1/masset/syncsinglebalance",
    accountsBalances: "/api/v1/masset/accountsbalances",
  },
  mscraper: {
    chain: "/api/v1/mscraper/chain",
  },
  mtask: {
    task: "/api/v1/mtask/task",
  },
  mwallet: {
    account: "/api/v1/mwallet/account",
    backpack: "/api/v1/mwallet/backpack",
    openEnergyOrb: "/api/v1/mwallet/openenergyorb",
    boost: "/api/v1/mwallet/boost",
    boostHistory: "/api/v1/mwallet/boosthistory",
    notifications: "/api/v1/mwallet/notifications",
    claim: "/api/v1/mwallet/claim",
    boostBoard: "/api/v1/mwallet/boostboard",
    twitterAuth: "/api/v1/mwallet/twitterauth",
    discordAuth: "/api/v1/mwallet/discordauth",
    errorCatch: "/api/v1/mwallet/errorcatch",
    leaderboard: "/api/v1/mwallet/leaderboard",
    bybitTaskDepositCallback: "/api/v1/mwallet/bybittaskdepositcallback",
  },
  payment: {
    taskPayment: "/api/v1/payment/taskpayment",
    quotePayment: "/api/v1/payment/quotepayment",
    confirmPayment: "/api/v1/payment/confirmpayment",
  },

  // Deprecated @241023
  dapp: {
    dapps: "/api/v1/dapp/dapps",
    watchlist: "/api/v1/dapp/watchlist",
    watchdapporftasset: "/api/v1/dapp/watchdapporftasset",
    searchdapp: "/api/v1/dapp/searchdapp",
    sort: "/api/v1/dapp/sort",
  },
  markets: {
    ftAssetsLabel: "/api/v1/asset/ftassetslabel",
    ftAssetsV2: "/api/v1/asset/ftassetsv2",
    ftAssetsV3: "/api/v1/asset/ftassetsv3",
    OHLC: "/api/v1/asset/ohlc",
    ftAssetDetail: "/api/v1/asset/ftassetdetail",
    vote: "/api/v1/asset/marketpredict",
    marketPredictInfo: "/api/v1/asset/marketpredictinfo",
    ftAssetsRiskInfo: "/api/v1/asset/ftassetsecurity",
  },
  account: {
    referralInfo: "/api/v1/stats/inviters",
  },
  kontosChain: {
    signerNativeTxsAssetsChange:
      "/api/v1/kontoschain/signernativetxsassetschange",
  },
};
