import { forwardRef, ReactNode, Ref } from "react";
import { Sheet, SheetRef } from "react-modal-sheet";
import { NoScrollerBarSheet } from "../wrapper/ReactiveWrapper";
import { BASIC_SHEET_INDEX } from "src/config";

interface Props {
  isOpen: boolean;
  children?: ReactNode;
  onClose?: () => void;
  mountPoint: Element | undefined;
  customHeight?: number;
  detent?: "full-height" | "content-height";
  disableDrag?: boolean;
  zExtraIndex?: number;
  disableScrollLocking?: boolean;
  snapPoints?: number[];
  initialSnap?: number | undefined;
  ref?: Ref<SheetRef>;
}

export const BottomSheet: React.FC<Props> = forwardRef(
  (
    {
      isOpen,
      children,
      onClose,
      mountPoint,
      detent,
      customHeight,
      disableDrag = false,
      zExtraIndex = 0,
      disableScrollLocking = true,
      snapPoints,
      initialSnap,
    }: Props,
    ref
  ) => {
    return (
      <NoScrollerBarSheet
        ref={ref}
        isOpen={isOpen}
        onClose={() => onClose?.()}
        mountPoint={mountPoint}
        detent={
          detent ? detent : !!customHeight ? "content-height" : "full-height"
        }
        disableDrag={disableDrag}
        style={{ zIndex: BASIC_SHEET_INDEX + zExtraIndex }}
        disableScrollLocking={disableScrollLocking}
        snapPoints={snapPoints}
        initialSnap={initialSnap}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            {!!customHeight ? (
              <div
                style={{
                  height: customHeight,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                {children}
              </div>
            ) : (
              <>{children}</>
            )}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} />
      </NoScrollerBarSheet>
    );
  }
);
