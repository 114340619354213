import styled from "styled-components";
import React from "react";
import tipsIcon from "src/assets/icons/tips.svg";
import { fontH5, fontMainText, fontSubtitle } from "@/style/style.global";
import { useTranslation } from "react-i18next";
import Divider from "@/components/divider/Divider";
import { KontosButton } from "@/components/button/KontosButton";
import blackArrowIcon from "src/assets/icons/components/recovery/black-arrow.svg";
import whiteArrowIcon from "src/assets/icons/components/recovery/white-arrow.svg";

const Container = styled.div`
  padding: 14px 20px 24px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Desc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const DescText = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontMainText}
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ManualButton = styled(KontosButton)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 99px;
  background: var(--Deep-800, #1a2535);

  color: var(--White, #fff);
  text-align: center;
  ${fontH5}
`;

const CopyButton = styled(KontosButton)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 99px;
  border: 1px solid var(--Deep-100, #cccfd2);
  background: var(--White, #fff);

  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontSubtitle}
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

type IProps = {
  onClickManual: () => void;
  onClickCopy: () => void;
};

export const RecoveryOptions: React.FC<IProps> = ({
  onClickManual,
  onClickCopy,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Desc>
        <TipsIcon src={tipsIcon} alt="" />
        <DescText>
          {t("Please choose one of the following methods for recovery.")}
        </DescText>
      </Desc>

      <Divider left={25} right={25} color={"var(--Deep-100, #CCCFD2)"} />

      <ButtonsWrapper>
        <ManualButton onClick={onClickManual}>
          {t("Manual Recovery")}
          <ArrowIcon src={whiteArrowIcon} alt="" />
        </ManualButton>
        <CopyButton onClick={onClickCopy}>
          {t("Copy Email Content for Recovery")}{" "}
          <ArrowIcon src={blackArrowIcon} alt="" />
        </CopyButton>
      </ButtonsWrapper>
    </Container>
  );
};
