import Modal from "@/components/modal/Modal";
import { useStores } from "@/hooks/useStore";
import {
  fontDescription,
  fontH5,
  fontH7,
  fontMainText,
} from "@/style/style.global";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import tipsIcon from "src/assets/icons/tips.svg";
import { KontosButton } from "@/components/button/KontosButton";
import { GuideVideo } from "./GuideVideo";

const StyledModal = styled(Modal)<{ $width: number; $height: number }>`
  width: ${(props) => props.$width - 32}px;
  height: fit-content;
  max-height: ${(props) => props.$height - 64}px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: var(--White, #fff);
  border-radius: 16px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    margin-right: 30px;
  }
  ::-ms-scrollbar {
    margin-right: 30px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const TipsIcon = styled.img`
  width: 42px;
  height: 24px;
`;

const Title = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontH5}
`;

const Tips = styled.span`
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontDescription};
`;

const Desc = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontMainText}
`;

const DescStrong = styled.span`
  color: var(--Kontos-Blue, #413dec);
  ${fontMainText}
`;

const CloseButton = styled(KontosButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  width: 100%;
  color: var(--White, #fff);
  text-align: center;
  ${fontH7}
`;

interface IProps {
  onClose: () => void;
}

export const ManualRecoveryGuideModal: React.FC<IProps> = observer(
  ({ onClose }) => {
    const { t } = useTranslation();
    const { uiStore } = useStores();

    return (
      <StyledModal
        $height={uiStore.containerSize.height}
        $width={uiStore.containerSize.width}
        zExtraIndex={9999999}
      >
        <MainContainer>
          <TipsIcon src={tipsIcon} alt="" />
          <Title>{t("Manual Recovery Guide")}</Title>
          <Tips>
            {t(
              "*This method allows your account to be restored immediately without any waiting."
            )}
          </Tips>
          <GuideVideo />
          <Desc>
            <Trans i18nKey={"trans-manual-recovery-guide-desc-v2"}>
              Send an email from your linked Gmail address to your own Gmail
              inbox.
              <br />
              <br />
              Open the email in your inbox and select{" "}
              <DescStrong>"View Original"</DescStrong> (or a similar option).
              <br />
              <br />
              Copy the content of the Original Message and paste it into the
              recovery form to submit.
            </Trans>
          </Desc>
        </MainContainer>

        <CloseButton onClick={onClose}>{t("Got it")}</CloseButton>
      </StyledModal>
    );
  }
);
