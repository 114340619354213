import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useMemo, useState } from "react";
import recover from "src/assets/images/recover.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import HorizontalLine from "src/components/line/HorizontalLine";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import PoweredBy from "src/components/common/powerdBy";
import RegisterStatusIcon, {
  inputStatusMap,
} from "src/components/start/RegisterStatusIcon";
import { fontBold } from "@/style/style.global";
import { Trans } from "react-i18next";
import { KONTOS_DISCORD_INVITE_LINK } from "@/config";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .description {
      color: var(--Deep-400, #80868f);
      text-align: center;

      /* Description */
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: var(--Kontos-Blue, #413dec);
        ${fontBold}
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
      }
    }

    .recover {
      width: 194px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .buttonWrap {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 16px;
  }
`;

const RightIconStyle = styled.span`
  padding-right: 10px;
`;

type IProps = {
  submitFunction: () => void;
  kontosName: string;
  setKontosName: (name: string) => void;
};

const RightIcon = () => {
  return <RightIconStyle>.OS</RightIconStyle>;
};

const NormalRecover: React.FC<IProps> = ({
  submitFunction,
  kontosName,
  setKontosName,
}) => {
  const [inputStatus, setInputStatus] =
    useState<keyof typeof inputStatusMap>("blur");

  const continueButtonDisabled = useMemo(() => {
    return kontosName === "" || inputStatus !== "success";
  }, [kontosName, inputStatus]);

  const handleElementClick = () => {
    submitFunction();
  };

  return (
    <Wrapper>
      <div className={"main"}>
        <img className={"recover"} src={recover} alt="recover" />
        <div className={"inputWrap"}>
          <InputWithIcons
            leftIcon={<RegisterStatusIcon status={inputStatus} />}
            rightIcon={<RightIcon />}
            placeholder={t("Your account username")}
            value={kontosName}
            onChange={(e) => {
              setKontosName(e.target.value?.toLowerCase());
            }}
            setInputStatus={setInputStatus}
            inputStatus={inputStatus}
            isCheckExist={true}
            onSubmit={() => {
              !continueButtonDisabled && handleElementClick();
            }}
          />
          <div className={"lineWrap"}>
            <HorizontalLine />
          </div>
          <div className={"description"}>
            <p>
              <Trans i18nKey={"trans-recover-tips-community"}>
                If you encounter any issues during account recovery, please
                reach out for support in our community:{" "}
                <a
                  href={KONTOS_DISCORD_INVITE_LINK}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "var(--Kontos-Blue, #413dec)" }}
                >
                  {{ KONTOS_DISCORD_INVITE_LINK } as any}
                </a>
              </Trans>
            </p>
          </div>
        </div>
      </div>
      <div className={"buttonWrap"}>
        <Button
          disabled={continueButtonDisabled}
          onClick={handleElementClick}
          text={t("Continue")}
          height={"56px"}
        />
        <PoweredBy />
      </div>
    </Wrapper>
  );
};

export default observer(NormalRecover);
