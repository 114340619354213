import { makeAutoObservable } from "mobx";

class LoadingStore {
  loadingCount = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isLoading() {
    return this.loadingCount > 0;
  }

  showLoading() {
    this.loadingCount++;
    if (this.loadingCount === 1) {
      document.body.style.overflow = "hidden";
    }
  }

  hideLoading() {
    if (this.loadingCount > 0) {
      this.loadingCount--;
      if (this.loadingCount === 0) {
        document.body.style.overflow = "auto";
      }
    } else {
      console.warn("Unbalanced hideLoading calls");
    }
  }

  forcedHideLoading() {
    this.loadingCount = 0;
    document.body.style.overflow = "auto";
  }
}

export const loadingStore = new LoadingStore();
