import { KontosButton } from "@/components/button/KontosButton";
import { KONTOS_SUPPORT_EMAIL } from "@/config";
import { fontH7 } from "@/style/style.global";
import { isMobileDevice, isValidGoogleEmail } from "@/utils/helper";
import { t } from "i18next";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-right: 12px;
  height: 32px;
  line-height: 32px;
  flex-shrink: 0;
`;

const A = styled.a`
  display: block;
  width: 100%;
  height: 100%;
`;

const SendButton = styled(KontosButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: #fff;
  ${fontH7}
  white-space: nowrap;
`;

type IProps = {
  index: number;
  mailObj: {
    account: string;
    pkStr: string | undefined;
    nonce: number;
  };
  emailArr: string[];
  disabled: boolean;
  manualAddress?: string;
};

export const WaitingEmailRightPart = ({
  index,
  mailObj,
  emailArr,
  disabled,
  manualAddress,
}: IProps) => {
  const [isSent, setIsSent] = useState<boolean>(false);

  return (
    <Container>
      {isValidGoogleEmail(emailArr[index]) ? (
        <A
          style={{ textDecoration: "none" }}
          href={
            isMobileDevice()
              ? `mailto:${
                  typeof manualAddress === "string"
                    ? manualAddress
                    : KONTOS_SUPPORT_EMAIL
                }?subject=Kontos Account Recovery&body=${JSON.stringify(
                  mailObj
                )}`
              : `https://mail.google.com/mail/?view=cm&fs=1&to=${
                  typeof manualAddress === "string"
                    ? manualAddress
                    : KONTOS_SUPPORT_EMAIL
                }&su=Kontos Account Recovery&body=${encodeURIComponent(
                  JSON.stringify(mailObj)
                )}`
          }
          onClick={() => setIsSent(true)}
          target="_blank"
          rel="noreferrer"
        >
          <SendButton disabled={disabled} onClick={() => setIsSent(true)}>
            {isSent ? t("Resend") : t("Send Email")}
          </SendButton>
        </A>
      ) : null}
    </Container>
  );
};
