import { t } from "i18next";
import { useCallback, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import PaymentReceive from "./payment-methods/PaymentReceive";
import PaymentOTC from "./payment-methods/PaymentOTC";
import { observer } from "mobx-react";
import { openUrl } from "src/utils/helper";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { usePaymentStore } from "@/store/trade/PaymentStore";
import PaymentSwitch from "./payment-items/PaymentSwitch";
import chooseAssetIcon from "src/assets/icons/contract/choose-asset.svg";
import { PaymentOptionType } from "./payment-items/PaymentSelect";
import { PaymentPlanFlow } from "@/flows/payment-plan/PaymentPlanFlow";
import Header from "@/components/common/header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  height: 100%;
`;

const DepositFlowContainer = styled.div`
  padding: 10px 16px;
  overflow-y: hidden;
`;

interface PaymentProps {
  isInSufficient: boolean;
  onBack: () => void;
  taskData: RespTaskPayment;
}

const Payment: React.FC<PaymentProps> = observer(
  ({ isInSufficient, onBack, taskData }) => {
    const { method, setMethod, isCustomPlan } = usePaymentStore();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [innerChoosing, setInnerChoosing] = useState<boolean>(false);
    const [backClickCount, setBackClickCount] = useState<number>(0);
    const paymentOpts: PaymentOptionType[] = useMemo(
      () => [
        {
          label: t("Payment Plan") as string,
          value: "plan",
          toastObj: {
            text: <span>{t("You don't have enough balance")}</span>,
            type: "warning",
          },
          isAllowed: !isInSufficient,
        },
        {
          label: t("Receive") as string,
          value: "receive",
          toastObj: {
            text: (
              <span>
                {t(
                  "You have enough balance and don't need to receive to execute your intention"
                )}
              </span>
            ),
            type: "warning",
          },
          isAllowed: isInSufficient,
        },
        {
          label: t("OTC") as string,
          value: "otc",
          toastObj: {
            text: (
              <span>
                {t(
                  "You have enough balance and don't need OTC to execute your intention"
                )}
              </span>
            ),
            type: "warning",
          },
          isAllowed: isInSufficient,
        },
      ],
      [isInSufficient]
    );

    const handleChoosing = useCallback((isOpen: boolean) => {
      setInnerChoosing(isOpen);
    }, []);

    const handleChangeSuccess = () => {
      onBack?.();
    };

    const handlePaymentMethodSelect = useCallback(
      (option: PaymentOptionType) => {
        setMethod(option.value);
      },
      [setMethod]
    );

    return (
      <Container ref={wrapperRef}>
        {/* Title */}
        <Header
          title={t("View Payment")}
          padding="8px 24px"
          enableBack
          callBack={() => {
            if (innerChoosing) {
              setInnerChoosing(false);
              setBackClickCount((prev) => prev + 1);
            } else {
              onBack && onBack();
            }
          }}
        />

        {/* Payment Plan Items */}
        {/* View Only */}
        {method === "plan" && (
          <PaymentPlanFlow
            mode={"view"}
            selectedPlan={isCustomPlan ? "custom" : "recommend"}
            selectedBalanceIds={[]}
            fixedBalanceIds={[]}
            onBack={onBack}
            recommendPlan={isCustomPlan ? [] : taskData.paymentPlan || []}
            customPlan={isCustomPlan ? taskData.paymentPlan || [] : []}
          />
        )}

        {isInSufficient && (
          <DepositFlowContainer>
            {!innerChoosing && (
              <PaymentSwitch
                icon={chooseAssetIcon}
                text={t("Switch pay mode")}
                selected={method}
                options={paymentOpts}
                onChange={handlePaymentMethodSelect}
              />
            )}

            {method === "receive" && (
              <PaymentReceive
                taskData={taskData}
                choosing={innerChoosing}
                onChoosing={handleChoosing}
                onSuccess={handleChangeSuccess}
              />
            )}

            {method === "otc" && (
              <PaymentOTC
                taskData={taskData}
                choosing={innerChoosing}
                backClickCount={backClickCount}
                onChoosing={handleChoosing}
                onSuccess={handleChangeSuccess}
              />
            )}
          </DepositFlowContainer>
        )}
      </Container>
    );
  }
);

export default Payment;
