import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BASIC_SHEET_INDEX } from "src/config";
import kontosGoogleAvatar from "src/assets/images/kontos-avatar-google.svg";
import { Instruction } from "src/components/instructions/Instruction";
import KontosInputV2 from "src/components/input/KontosInputV2";
import RegisterStatusIcon, { inputStatusMap } from "../RegisterStatusIcon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { KontosButton } from "src/components/button/KontosButton";
import spinIco from "src/assets/icons/dapp/spin.png";
import { isGoogleEmail } from "src/components/button/GoogleLoginBtn";
import {
  callEmailRegisterCode,
  callEmailRegisterVerify,
} from "src/apis/email-apis";
import toast from "src/components/toast/Toast";
import { CodeVerifier } from "src/components/code/CodeVerifier";
import bigSpinIcon from "src/assets/icons/spin-loading-big.svg";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: var(--White, #fff);
  max-width: 375px;
  min-width: 330px;
  border-radius: 16px;
`;

const AvatarIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const BottomArea = styled.div`
  height: 34px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FinishButton = styled.button`
  border: none;
  color: var(--error-notice, #ff1e2b);
  text-align: center;
  ${fontBold};
  font-size: 16px;
  line-height: 20px;
`;

const StyledKontosButton = styled(KontosButton)`
  min-width: 82px;
  height: 32px;
  padding: 8px 21px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--White, #fff);
  text-align: center;
  ${fontBold};
  font-size: 14px;
`;

const SpinIcon = styled.img`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  overflow: auto;
`;

const InputErrorText = styled.div`
  margin: -8px 0;
  width: calc(100% - 8px);
  color: var(--error-notice, #ff1e2b);
  text-align: left;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  padding-left: 6px;
`;

interface VerifyButtonProps {
  requesting: boolean;
  disabled: boolean;
  onClick: () => void;
}

const VerifyButton: React.FC<VerifyButtonProps> = ({
  requesting,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledKontosButton
      $loading={requesting}
      onClick={onClick}
      disabled={disabled}
    >
      {requesting ? <SpinIcon src={spinIco} alt="loading..." /> : t("Verify")}
    </StyledKontosButton>
  );
};

const LoadingIcon = styled.img`
  width: 48px;
  height: 48px;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
`;

interface Props {
  existedEmails: string[];
  accountName: string;
  onAddEmail: (email: string) => void;
  onClose: () => void;
}

const initialCount = 120;

export const EmailVerificationPopup: React.FC<Props> = ({
  existedEmails,
  accountName,
  onAddEmail,
  onClose,
}) => {
  const { t } = useTranslation();
  const [inputStatus, setInputStatus] =
    useState<keyof typeof inputStatusMap>("blur");
  const [email, setEmail] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [count, setCount] = useState(initialCount);
  const [isActive, setIsActive] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [key, setKey] = useState(0);
  const inputRef = useRef<{ focus: () => void }>(null);
  const [inputErrorDesc, setInputErrorDesc] = useState<string>("");

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (isActive && count > 0) {
      timerId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (count === 0) {
      setIsActive(false);
    }

    return () => clearInterval(timerId);
  }, [isActive, count]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSendCode = useCallback(async () => {
    if (existedEmails.includes(email)) {
      toast({
        type: "warning",
        text: t("The email already exists"),
      });
      setEmail("");
      setInputStatus("blur");
      inputRef.current?.focus();
      return;
    }
    if (isGoogleEmail(email)) {
      try {
        setSending(true);
        const { success } = await callEmailRegisterCode({
          account: accountName,
          email,
        });
        if (success) {
          setSendSuccess(true);
          setIsActive(true);
          setCount(initialCount);
          toast({
            type: "success",
            text: t(
              "We have sent a verification code to your email. Please check your inbox."
            ),
          });
        } else {
          toast({
            type: "error",
            text: t("System error, failed to send email"),
          });
        }
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : t("System error");
        toast({
          type: "error",
          text: errorMessage,
        });
      } finally {
        setSending(false);
      }
    }
  }, [accountName, email, existedEmails, t]);

  const handleVerifyCode = useCallback(
    async (code: string): Promise<boolean> => {
      if (code.length !== 6) {
        return false;
      }
      try {
        setVerifying(true);
        const { success } = await callEmailRegisterVerify({
          account: accountName,
          code,
        });
        if (success) {
          onAddEmail(email);
          onClose();
          return true;
        } else {
          toast({
            type: "error",
            text: t("Verification code mismatch"),
          });
          return false;
        }
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : t("System error");
        toast({
          type: "error",
          text: errorMessage,
        });
        // If expiration, reset this component
        if (
          errorMessage.toLowerCase().includes("verification code expiration")
        ) {
          setKey((prevKey) => prevKey + 1);
        }
        return false;
      } finally {
        setVerifying(false);
      }
    },
    [accountName, email, onAddEmail, onClose, t]
  );

  return (
    <Overlay key={key} zIndex={BASIC_SHEET_INDEX} outerOpacity={0.2}>
      <Container>
        <AvatarIcon src={kontosGoogleAvatar} />

        {sendSuccess ? (
          <>
            <CodeVerifier
              countdown={count}
              retrying={sending}
              verifying={verifying}
              onRetry={handleSendCode}
              onVerify={handleVerifyCode}
            />
          </>
        ) : (
          <KontosInputV2
            leftIcon={<RegisterStatusIcon status={inputStatus} type="email" />}
            rightIcon={
              <VerifyButton
                requesting={sending}
                onClick={handleSendCode}
                disabled={
                  !isGoogleEmail(email) ||
                  inputStatus === "error" ||
                  inputStatus === "loading"
                }
              />
            }
            placeholder={t("Please enter Gmail")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value?.toLowerCase());
            }}
            setInputStatus={setInputStatus}
            setInputErrorDesc={setInputErrorDesc}
            inputStatus={inputStatus}
            isCheckExist={true}
            checkType="email"
            rightDistance={4}
            inputStyle={{ paddingRight: "92px" }}
            wrapperStyle={{ marginBottom: "0" }}
            ref={inputRef}
            onSubmit={handleSendCode}
          />
        )}
        {inputStatus === "error" && inputErrorDesc !== "" && (
          <InputErrorText>{inputErrorDesc}</InputErrorText>
        )}

        {verifying ? (
          <LoadingIcon src={bigSpinIcon} />
        ) : (
          <>
            <Instruction
              style={{ margin: "0 8px" }}
              type={"warning"}
              title={t("Currently, only Gmail is accepted!")}
              contents={[
                t(
                  "You are adding a security email to your Kontos account, so please ensure you remember this email address."
                ),
              ]}
            />
            <BottomArea>
              <FinishButton onClick={onClose}>{t("Close")}</FinishButton>
            </BottomArea>
          </>
        )}
      </Container>
    </Overlay>
  );
};
