import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { MAX_WIDTH } from "src/config";
import { UiSettingStore } from "./ui/UiSettingStore";
import {
  ROUTE_TRADE,
  ROUTE_TRADE_BUY,
  ROUTE_TRADE_SELL,
} from "src/router/router-config";
import { debounce } from "lodash";

export class UiStore {
  rootStore: RootStore;
  uiSettingStore: UiSettingStore = new UiSettingStore(this);
  isOverWidth: boolean = window.innerWidth > MAX_WIDTH;
  innerWidth?: number;
  innerHeight?: number;
  tradeRoute: string = ROUTE_TRADE;
  monitoringContainerSize: boolean = false;
  containerSize: { width: number; height: number } = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    const debouncedHandleResize = debounce(this.handleResize.bind(this), 300);
    window.addEventListener("resize", debouncedHandleResize);
    this.cleanupResizeListener = () =>
      window.removeEventListener("resize", debouncedHandleResize);
  }

  handleResize() {
    this.isOverWidth = window.innerWidth > MAX_WIDTH;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  cleanupResizeListener?: () => void;

  setTradeRouteBuy = () => {
    this.tradeRoute = `${ROUTE_TRADE}${ROUTE_TRADE_BUY}`;
  };

  setTradeRouteSell = () => {
    this.tradeRoute = `${ROUTE_TRADE}${ROUTE_TRADE_SELL}`;
  };

  startMonitoringContainerSize = () => {
    this.monitoringContainerSize = true;
  };

  stopMonitoringContainerSize = () => {
    this.monitoringContainerSize = false;
  };

  setContainerSize = (size: { width: number; height: number }) => {
    this.containerSize = size;
  };
}
