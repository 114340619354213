import { TEMP_EXPLORER_URL } from "@/config";
import { API } from "./core/API";
import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import * as apiTypes from "./types";

export interface ReqAccount {
  account: string;
  offset: number;
  limit: number;
}

export interface RespAccount {
  accountDetail: apiTypes.ApiAccountDetail;
  accounts: apiTypes.ApiAccount[];
  hasMore: boolean;
}

export const callAccount = async (
  data: ReqAccount,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccount> => {
  return await request<ReqAccount, RespAccount>(
    API.maa.account,
    data,
    endpoint
  );
};

export interface ReqSearchAccountNames {
  regex: string;
}

export interface RespSearchAccountNames {
  accounts: string[];
}

let searchAccountNamesController: AbortController;

export const callSearchAccountNames = async (
  data: ReqSearchAccountNames,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespSearchAccountNames> => {
  searchAccountNamesController && searchAccountNamesController.abort();
  searchAccountNamesController = new AbortController();
  return await request<ReqSearchAccountNames, RespSearchAccountNames>(
    API.maa.searchAccountNames,
    data,
    endpoint,
    searchAccountNamesController.signal
  );
};

export interface ReqAccountThresholdActions {
  account: string;
}

export interface RespAccountThresholdActions {
  actions: apiTypes.ApiThresholdAction[];
}

export const callAccountThresholdActions = async (
  data: ReqAccountThresholdActions,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountThresholdActions> => {
  return await request<ReqAccountThresholdActions, RespAccountThresholdActions>(
    API.maa.accountThresholdActions,
    data,
    endpoint
  );
};

export interface ReqWardsThresholdActions {
  account: string;
}

export interface RespWardsThresholdActions {
  actions: apiTypes.ApiThresholdAction[];
}

export const callWardsThresholdActions = async (
  data: ReqWardsThresholdActions,
  endpoint: string = TEMP_EXPLORER_URL
) => {
  return request<ReqWardsThresholdActions, RespWardsThresholdActions>(
    API.maa.wardsThresholdActions,
    data,
    endpoint
  );
};

export interface ReqAccountNewPubKeyAction {
  account: string;
}

export interface RespAccountNewPubKeyAction {
  action: apiTypes.ApiThresholdAction;
}

export const callAccountNewPubKeyAction = async (
  data: ReqAccountNewPubKeyAction,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountNewPubKeyAction> => {
  return await request<ReqAccountNewPubKeyAction, RespAccountNewPubKeyAction>(
    API.maa.accountNewPubKeyAction,
    data,
    endpoint
  );
};

export interface ReqNonce {
  account: string;
}

export interface RespNonce {
  nonce: number;
}

export const callNonce = async (
  data: ReqNonce,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespNonce> => {
  return await request<ReqNonce, RespNonce>(API.maa.nonce, data, endpoint);
};

export interface ReqAccountNewPubKeyActionProcess {
  account: string;
  pubKey: string;
}

export interface RespAccountNewPubKeyActionProcess {
  emailRecoveries: {
    [key: string]: apiTypes.ApiEmailNewPubKeyActionProcess;
  };
}

export const callAccountNewPubKeyActionProcess = async (
  data: ReqAccountNewPubKeyActionProcess,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountNewPubKeyActionProcess> => {
  return await request<
    ReqAccountNewPubKeyActionProcess,
    RespAccountNewPubKeyActionProcess
  >(API.maa.accountNewPubKeyActionProcess, data, endpoint);
};

let accountNewPubKeyActionProcessController: AbortController;

export const callAccountNewPubKeyActionProcessWithAbort = async (
  data: ReqAccountNewPubKeyActionProcess,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountNewPubKeyActionProcess> => {
  accountNewPubKeyActionProcessController &&
    accountNewPubKeyActionProcessController.abort();
  accountNewPubKeyActionProcessController = new AbortController();
  return await request<
    ReqAccountNewPubKeyActionProcess,
    RespAccountNewPubKeyActionProcess
  >(
    API.maa.accountNewPubKeyActionProcess,
    data,
    endpoint,
    accountNewPubKeyActionProcessController.signal
  );
};

export interface ReqRegisterAccount {
  name: string;
  pubKey: string;
  emails: string[];
  guardians: string[];
  threshold: number;
  inviter: string;
}

export interface RespRegisterAccount {}

export const callRegisterAccount = async (
  data: ReqRegisterAccount,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespRegisterAccount> => {
  return await request<ReqRegisterAccount, RespRegisterAccount>(
    API.maa.registerAccount,
    data,
    endpoint
  );
};

export interface ReqUpdateAccountGuardian {
  account: string;
  emails: string[];
  guardians: string[];
  threshold: number;
  expiredAt: number;
  signature: string;
}

export interface RespUpdateAccountGuardian {}

export const callUpdateAccountGuardian = async (
  data: ReqUpdateAccountGuardian,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespUpdateAccountGuardian> => {
  return await request<ReqUpdateAccountGuardian, RespUpdateAccountGuardian>(
    API.maa.updateAccountGuardian,
    data,
    endpoint
  );
};

export interface ReqRecoverAccountBySeedProof {
  account: string;
  newRecoveryPubKey: string;
  proof: string;
}

export interface RespRecoverAccountBySeedProof {}

export const callRecoverAccountBySeedProof = async (
  data: ReqRecoverAccountBySeedProof,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespRecoverAccountBySeedProof> => {
  return await request<
    ReqRecoverAccountBySeedProof,
    RespRecoverAccountBySeedProof
  >(API.maa.recoverAccountBySeedProof, data, endpoint);
};

export interface ReqRecoverAccountByGuardian {
  sender: string;
  executor: string;
  newPubKey: string;
  isApprove: boolean;
  expiredAt: number;
  signature: string;
}

export interface RespRecoverAccountByGuardian {}

export const callRecoverAccountByGuardian = async (
  data: ReqRecoverAccountByGuardian,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespRecoverAccountByGuardian> => {
  return await request<
    ReqRecoverAccountByGuardian,
    RespRecoverAccountByGuardian
  >(API.maa.recoverAccountByGuardian, data, endpoint);
};

export interface ReqRevertUpdateAccountGuardian {
  account: string;
  expiredAt: number;
  signature: string;
}

export interface RespRevertUpdateAccountGuardian {}

export const callRevertUpdateAccountGuardian = async (
  data: ReqRevertUpdateAccountGuardian,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespRevertUpdateAccountGuardian> => {
  return await request<
    ReqRevertUpdateAccountGuardian,
    RespRevertUpdateAccountGuardian
  >(API.maa.revertUpdateAccountGuardian, data, endpoint);
};

export interface ReqAccountWards {
  account: string;
}

export interface RespAccountWards {
  wards: string[] | null;
}

export const callAccountWards = async (
  data: ReqAccountWards,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountWards> => {
  return await request<ReqAccountWards, RespAccountWards>(
    API.maa.accountWards,
    data,
    endpoint
  );
};

export interface ReqRecoverAccountByEmail {
  account: string;
  originalEmail: string;
}

export interface RespRecoverAccountByEmail {
  nonce: number;
  pubKey: string;
  remainApprovals: number;
}

export const callRecoverAccountByEmail = async (
  data: ReqRecoverAccountByEmail,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespRecoverAccountByEmail> => {
  return await request<ReqRecoverAccountByEmail, RespRecoverAccountByEmail>(
    API.maa.recoverAccountByEmail,
    data,
    endpoint
  );
};
